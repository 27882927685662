<template>
    <div>
        <div class="rent-device">
            <div class="round-bg"></div>
            <div class="device-img">
                <img
                    :src="require('@/assets/imgs/png/0604-c.png')"
                    alt="device"
                />
            </div>
            <div class="content">
                <h4>你欲租借的設備編號為:</h4>
                <p class="device-id">{{ this.$route.params.deviceID }}</p>
            </div>
            <div class="reminder">
                <h4>請確認您要租借的行動電源編碼</h4>
                <h4>與機台QR CODE下的編碼後四碼是否相同</h4>
                <h4>若否請點選「返回上頁」重新選擇</h4>
            </div>
            <div class="button-section">
                <button
                    type="button"
                    class="capsule-button text-1250 capsule-big"
                    @click="$_goBack()"
                >
                    返回上頁
                </button>
                <button
                    type="button"
                    class="capsule-button text-1250 capsule-big"
                    @click="$_rentPowerbank()"
                >
                    立即租借
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { handleErr } from "@/helpers";
export default {
    name: "RentDevice",
    data() {
        return {};
    },
    methods: {
        $_goBack() {
            this.$router.go(-1);
        },
        async $_rentPowerbank() {
            try {
                const res = await this.$axios.post("customer/order/powerbank", {
                    deviceSn: this.$route.params.deviceID,
                });
                // console.log("res", res);
                console.log(res, "res rent device");
                if (res.data.success) {
                    const storeID = localStorage.getItem("storeID");
                    this.$router.push({
                        name: "store",
                        params: {
                            storeID: storeID,
                            rentSuccess: true,
                        },
                    });
                } else {
                    handleErr({
                        app: this.$parent,
                        msg: "設備異常，請確認機器是否開機，或與旅電科技聯繫",
                    });
                    this.$_goBack();
                }
            } catch (error) {
                console.log(error, "error rent device");
                handleErr({ ...error, app: this.$parent });
            }
        },
    },
    mounted() {
        const token = window.$cookies.get("token");
        if (token) {
            this.$axios.defaults.headers.common["token"] = token;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
        }
    },
};
</script>
<style lang="scss" scoped></style>
